const config = {
  "broker": "1",
  "name": "Saudi",
  "registrationBundle": "english",
  "defaultCountryName": "Saudi Arabia",
  "defaultCountryCode": "SA",
  "fxgeoip_code": "SA",
  languageCode: 'en_sa',
  languageName:'English',
  spokenLanguageCode:'en',
  direction:'ltr',
  footerDisclaimer:'697',
  brand: 'iforex'
};
module.exports = config;